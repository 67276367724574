<template>
    <b-table
        show-empty
        empty-text="No hay registros para mostrar, haga una busqueda"
        empty-filtered-text="No se han encontrado resultados"
        small
        responsive
        stacked="md"
        :busy.sync="isloadingProcess"
        :items="filteredOrders"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
    >
        <template #table-busy>
            <div class="text-center my-2">
                <b-spinner class="align-middle" label="Loading..." variant="success"></b-spinner>
                <strong> Cargando...</strong>
            </div>
        </template>
        <template #cell(invoice)="row">
            <p class="d-flex">
                <strong>{{row.item.invoice}}</strong>
                <b-button
                    variant="flat-secondary"
                    class="btn-icon"
                    :id="'popover-button-variant'+row.item.id"
                ><b-icon-info-circle-fill/>
                </b-button>
            </p>

            <b-popover :target="'popover-button-variant'+row.item.id" variant="primary" triggers="focus">
                <template #title>Datos del cliente</template>
                <b>Nombre completo:</b> {{row.item.customer.name}} {{row.item.customer.lastname}} <br>
                <b>Email:</b> {{row.item.customer.email}}<br>
                <b>phone:</b> {{row.item.customer.phone}}<br>
            </b-popover>
        </template>
        <template #cell(customername)="row">
            <strong>Cliente: </strong>{{row.item.customer.name}} {{row.item.customer.lastname}} <br>
            <strong>Tipo Cliente: </strong>{{row.item.customertype || ''}}<br>
            <strong>Vendedor: </strong>{{ row.item.vendedor }} <br>
            <span ><strong style="font-size: 14px;">Fecha orden:</strong> <span style="font-size: 13px;">{{row.item.orderDate}}</span> </span><br>
            <span><strong style="font-size: 14px;">Fecha pago:</strong> <span style="font-size: 13px;">{{row.item.paymentdate || 'No pagado'}}</span> </span><br>
            <span class="badge badge-success mr-2">
            Confirmado: {{row.item.confirmados}}
            </span>
            <span class="badge badge-success mr-2">
            Operado: {{row.item.operados}}
            </span>
        </template>
        <template #cell(producto)="row">
            <div v-if="row.item.detail.length">
                <ul>
                    <li v-for="(p, index) in row.item.detail" :key="index" style="font-size: .9em">
                        {{p.itemsold}}
                    </li>
                </ul>
            </div>
        </template>
        <template #cell(totalSale)="row">
            <span>{{setCurrency(row.item)}} {{row.item.currency.code}}</span>
        </template>
        <template #cell(actions)="row">
            <div class="text-nowrap text-center">
                <!-- Dropdown -->
                <b-dropdown toggle-class="p-0" no-caret right>
                    <template #button-content>
                        <b-button variant="primary" size="sm" v-b-tooltip.hover.top="'Ver Acciones'">
                            <feather-icon icon="GridIcon" size="16" class="align-middle" />
                        </b-button>
                    </template>
                    <b-dropdown-item @click="setActualOrder( row.item )" :to="{ name: 'order-details', params: { order: 'order-'+row.item.id } }">
                        <feather-icon icon="EditIcon" size="14"/> Editar Articulo
                    </b-dropdown-item>
                    <b-dropdown-item @click="showModalCliente( row.item.id )" v-if=" row.item.saleuser_channelid == user.canalventa.id ">
                        <feather-icon icon="UserCheckIcon" size="14"/> Editar Cliente
                    </b-dropdown-item>
                    <b-dropdown-item @click="showModalReenvioMailCliente( row.item.id )">
                        <feather-icon icon="MailIcon" size="14"/> Reenviar Correo
                    </b-dropdown-item>
                    <b-dropdown-item @click="showModalTransactions( row.item )">
                        <feather-icon icon="DollarSignIcon" size="14" /> Ver Transacciones
                    </b-dropdown-item>
                    <b-dropdown-item :disabled="loadOrder(row.item)" @click="changesStatus( {order: row.item, nullOrder: true} )" v-if="canNullThisOrder(row.item)">
                        <feather-icon style="color: red" icon="SlashIcon" size="14" /> Anular Venta <b-spinner v-if="loadOrder(row.item)" small class="align-middle" label="Loading..." variant="success"/>
                    </b-dropdown-item>
                    <b-dropdown-item :disabled="loadOrder(row.item)" @click="changesStatus( {order: row.item, reactivate: true} )" v-if="canReactivateThisOrder(row.item)">
                        <feather-icon style="color: lime" icon="CheckCircleIcon" size="14" /> Reactivar Venta <b-spinner v-if="loadOrder(row.item)" small class="align-middle" label="Loading..." variant="success"/>
                    </b-dropdown-item>

                    <Transactions :orderTransaction="orderTransaction" :order="row.item.id"/>
                    <ModalReference :order="row.item" />
                    <ModalSendEmail :order="row.item" />

                </b-dropdown>
            </div>
        </template>
        <template #cell(paymentmethod_name)="row">
            <span>{{row.item.paymentmethod_name}}</span> <br>
            <span v-if="row.item.isEpayment">
                <b-button
                    variant="flat-secondary"
                    class="btn-icon"
                    size="sm"
                    :id="'button-Epayment'+row.item.id"
                >
                    <b-icon-info-circle variant="info" v-if="!row.item.tokenpayment"/>
                    <b-icon-exclamation-circle variant="success" v-else/>
                </b-button>
                <b-popover :target="'button-Epayment'+row.item.id" variant="primary" triggers="hover">
                    <template #title>Pago con Epayment</template>
                    <span>Esta orden fue solicitada como Epayment.</span><br>
                    <span v-if="row.item.tokenpayment">La orden ya cuenta con un pago.</span><br>
                </b-popover>
            </span>
            <span v-if="row.item.paymentmethod_name == 'Payment Link'">
                <b-button
                    variant="flat-secondary"
                    class="btn-icon"
                    size="sm"
                    :id="'button-timer'+row.item.id"
                >
                <b-icon-clock variant="primary"/>
                </b-button>
                <b-popover
                  :target="'button-timer'+row.item.id"
                  :variant="timeStatus == 'Expirado' ? 'danger' : 'success'"
                  triggers="hover"
                >
                    <template #title>Vigencia del Payment Link</template>
                    <Timer
                        :starttime="formatedDate(row.item.orderDate)"
                        :endtime="endDate(row.item.orderDate)"
                        expired="Vigencia expirada."
                        running="Vigencia disponibles."
                        upcoming="Para empezar a pagar."
                        estilo="md"
                        :stop="row.item.orderstatusname == 'Annulled' || row.item.orderstatusname == 'Cancelled'"
                        :formato="{days: true, hours: true, minutes: true, seconds: false,}"
                        @status-time="setTimeStatus"
                    />
                </b-popover>
            </span>
        </template>
    </b-table>
</template>
<script>

import { mapState, mapMutations, mapActions } from 'vuex'
import ModalReference from '@/modules/mySales/components/mySales/ModalReference'
import ModalSendEmail from '@/modules/mySales/components/mySales/ModalSendEmail'
import Timer from '@/modules/mySales/components/mySales/Timer'
import Transactions from '@/modules/mySales/components/orderDetail/Transactions'
import { VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { showAlertMessage, toCurrency, sweetAlert } from '@/helpers/helpers'

export default {
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    mounted(){
        this.fetchCancellationReasons(this.user.canalventa.id)
    },
    components:{
        ModalReference,
        Transactions,
        ModalSendEmail,
        Timer
    },
    props:{
        filteredOrders:{
            type: Array,
            required: true
        },
        fields:{
            type: Array,
            required: true
        },
        currentPage:{
            type: Number,
            required: true
        },
        perPage:{
            type: Number,
            required: true
        },
        filter:{
            type: String,
            required: false
        },
        filterOn:{
            type: Array,
            required: true
        },
        sortBy:{
            type: String,
            required: true
        },
        sortDesc:{
            type: Boolean,
            required: true
        },
        sortDirection:{
            type: String,
            required: true
        },
        onFiltered:{
            type: Function,
            required: true
        },
        isloadingProcess:{
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            couponLink: process.env.VUE_APP_ORDER_DETAIL_COUPON_PDF,
            orderTransaction: [],
            orderId: null,
            timeStatus: null,
            loadingOrder: null,
            isloadingOrder: false
        }
    },
    computed:{
        ...mapState('auth',['user']),
        ...mapState('start',['currencies']),
        ...mapState('mySales',['cancellationReasons', 'actualfilters']),
    },
    methods:{
        ...mapMutations('mySales', ['setCurrentOrder']),
        ...mapActions('mySales', ['fetchSingleTransactionsOrder']),
        ...mapActions('mySales', ['fetchCancellationReasons', 'nullOrder', 'reactivateOrder']),
        showModalCliente( id) {
            this.$root.$emit("bv::show::modal", 'modal-reference' + id )
        },
        showModalReenvioMailCliente( id) {
            this.$root.$emit("bv::show::modal", 'modal-resend-mail' + id )
        },
        async showModalTransactions( order) {
            this.orderTransaction = []
            this.setCurrentOrder(order)
            const response = await this.fetchSingleTransactionsOrder(order.id)

            if (response.length > 0){                
                this.orderTransaction = response
                this.orderId = order.id
                this.$root.$emit("bv::show::modal", 'modal-transactions' + order.id )
            } else return showAlertMessage('Aviso', 'BellIcon', `La orden ${order.id} no cuenta con transacciones`, 'warning', 4000, 'bottom-right')
        },
        setActualOrder(order){
            this.setCurrentOrder(order)
        },
        setCurrency(data){
            return toCurrency(parseFloat(data.totalSale) * parseFloat(data.currencyExchangeRate), data.currency.code)
        },
        formatedDate(date){
            const newDate = date.split('-')
            return new Date(newDate[0], newDate[1] - 1, newDate[2])
        },
        endDate(date){
            let newDate = date.split('-')
            return new Date(newDate[0], parseInt(newDate[1]) - 1, parseInt(newDate[2]) + 3)
        },
        setTimeStatus(status){
            this.timeStatus = status
        },
        canNullThisOrder(data){
            const usuario = data.saleuser_id == this.user.idUser
            const status = data.orderstatusname == 'Reserved' || data.orderstatusname == 'Requested'
            const valid = usuario && status
            return valid
        },
        canReactivateThisOrder(data){
            const status = data.orderstatusname == 'Annulled'
            const channel = data.saleuser_channelid == this.user?.canalventa?.id
            const valid = channel && status
            return valid
        },
        loadOrder(order){
            const validOrder = order.id == this.loadingOrder
            return this.isloadingOrder && validOrder
        },
        iconItem(detail){
            if (detail?.booking?.id) return '🏩'
            else if (detail?.itinerary?.id) return '🚐'
            else if (detail?.package) return '📦'
            else if (detail?.event) return '🎉'
            else if (detail?.categoryname == "Package Items") return '📦🏷️'
            else return '🏷️'
        },
        async changesStatus(info){
            const {order, nullOrder, reactivate} = info
            let swalert
            const payload = {
                id: order.id,
                idUser: this.user.idUser,
            }
            if (nullOrder) swalert = {
                title: "Anulando orden: " + order.invoice,
                message: "¿Está seguro de anular esta orden?",
                icon: "question",
                confirmButton: "Anular",
                cancelButton: "Cancelar",
            }
            else if (reactivate) swalert = {
                title: "Reactivando orden: " + order.invoice,
                message: "¿Está seguro de rectivar esta orden? La orden pasará a estado 'Requested'",
                icon: "question",
                confirmButton: "Reactivar",
                cancelButton: "Cancelar",
            }

            const { isConfirmed } = await sweetAlert(swalert);

            if (isConfirmed){
                this.isloadingOrder = true
                this.loadingOrder = order.id
                let response = null
                if (nullOrder) response = await this.nullOrder(payload)
                else if (reactivate) response = await this.reactivateOrder(payload)
                if (response) {
                    this.isloadingOrder = false
                    this.loadingOrder = null
                    const data = this.actualfilters
                    this.$emit('get-Orders-by-Date-Range-filter', data)
                } else {
                    this.isloadingOrder = false
                    this.loadingOrder = null
                }
            }
        },
    }
}
</script>
<style scoped>
.custom-margin{
    border-bottom: 1px solid #b8c2cc;
}
</style>
