<template>
  <b-modal
    size="lg"
    :id="'modal-transactions'+ order"
    :title="'Historial de Transacciones de la orden ' + order"
    ok-only
    hide-footer
  >
    <b-row>
      <b-col class="col-md-12">
        <b-card v-if="transactions.length">
          <b-card-body>
            <b-table 
              :items="transactions"
              :fields="fields"
              bordered
              responsive
              small
            />
          </b-card-body>
        </b-card>
        <b-card v-else>
          <b-alert show variant="danger">
            <div class="alert-body text-center"> <span>Esta orden no tiene transacciones realizadas</span></div>
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>

export default {
  props:{
    orderTransaction:{
      type: Array,
      required: true
    },
    order:{
      type: Number,
      default: ''
    }
  },
  data(){
    return {
      fields: [
        {key: 'date_transaction_bank' , label: 'Fecha de pago'},
        {key: 'Importe' , label: 'Importe'},
        {key: 'pasarela' , label: 'Pasarela'},
        {key: 'referencia' , label: 'Autorización'},
        {key: 'cc_banco' , label: 'Cuenta bancaria'},
        {key: 'estado' , label: 'Estado'}
      ],
    }
  },
  computed: {
    transactions(){
      let transactions = []
      this.orderTransaction.forEach( transaction => {
        const { paid_amount,  cc_bank, transactionstatus, date_transaction_bank, paid_currency, pasarela,auth_bank} = transaction
        const { name } = transactionstatus
        const { code } = paid_currency
        const trans = {
          date_transaction_bank,
          Importe: `$ ${paid_amount} ${code}`,
          pasarela: pasarela?.name,
          referencia: auth_bank,
          cc_banco: cc_bank,
          // folio_banco: folio_bank,
          estado: name,
          // cantidad_pagada: paid_amount,
        }
        transactions.push(trans)
      })
      return transactions
    }
  }
}
</script>