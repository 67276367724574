<template>
  <b-modal
    size="lg"
    :id="'modal-reference'+order.id"
    :title="order.invoice"
    ok-only
    hide-footer
>
    <b-tabs>
    <b-tab title="Cliente" active>
        <ValidationObserver ref="observerReference" v-slot="{ invalid }">
            <b-row>
                <b-col md="6">
                    <ValidationProvider rules="required" name="nombre">
                        <b-form-group label="Nombre" slot-scope="{ valid, errors }"> 
                        <b-form-input
                            class="form-control"
                            type="text"
                            v-model="order.customer.name"
                            :state="errors[0] ? false : (valid ? true : null)" 
                        >
                        </b-form-input>   
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                
                <b-col md="6">
                    <ValidationProvider rules="required" name="apellido">
                        <b-form-group label="Apellido" slot-scope="{ valid, errors }"> 
                        <b-form-input
                            class="form-control"
                            type="text"
                            v-model="order.customer.lastname"
                            :state="errors[0] ? false : (valid ? true : null)" 
                        >
                        </b-form-input>   
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col md="6">
                    <ValidationProvider rules="required" name="email">
                        <b-form-group label="Email" slot-scope="{ valid, errors }"> 
                        <b-form-input
                            class="form-control"
                            type="email"
                            v-model="order.customer.email"
                            :state="errors[0] ? false : (valid ? true : null)" 
                        >
                        </b-form-input>   
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="6">
                    <ValidationProvider rules="" name="phone">
                        <b-form-group label="Teléfono" slot-scope="{ valid, errors }"> 
                        <b-form-input
                            class="form-control"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            maxlength="20"
                            type="text"
                            v-model="order.customer.phone"
                            :state="errors[0] ? false : (valid ? true : null)" 
                            @keypress="formatPhoneNumber" 
                        >
                        </b-form-input>   
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                
                <b-col md="12">
                    <div class="float-right">
                        <b-button
                            variant="primary"      
                            :disabled="invalid || isSavingCustomer"
                            @click="updateDataCustomer(order.customer)"
                        > <b-spinner small v-if="isSavingCustomer"/> Guardar
                        </b-button>
                    </div>                    
                </b-col>
            </b-row> 
        </ValidationObserver>      
    </b-tab>
    <b-tab title="Referencia">
      <b-row class="mb-2">
            <b-col md="6">
                <strong>Cliente: </strong>{{order.customername}} <br>
                <strong>Tipo de cliente: </strong>{{order.customertype}} <br>
                <strong>Email: </strong>{{order.customer.email}} <br>
                <strong>Teléfono: </strong>{{order.customer.phone}}

            </b-col>
            <b-col md="6">
                <strong>Vendedor: </strong>{{order.vendedor}} <br>
                <strong>Fecha orden: </strong>{{order.orderDate}} <br>
                <strong>Fecha pago: </strong>{{order.paymentdate}} <br>
                <strong>Confirmados: </strong>{{order.confirmados}}                
            </b-col>            

        </b-row>            
        <ValidationObserver ref="observerReference" v-slot="{ invalid }">
            <b-row>
                <b-col md="6">
                    <ValidationProvider rules="" name="Referencia">
                        <b-form-group label="Referencia" slot-scope="{ valid, errors }"> 
                        <b-form-input
                            class="form-control"
                            type="text"
                            v-model="order.paymentreference"
                            placeholder="Ingrese referencia"
                            :state="errors[0] ? false : (valid ? true : null)" 
                        >
                        </b-form-input>   
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="6">
                    <ValidationProvider rules="" name="Referido">
                        <b-form-group label="Referido" slot-scope="{ valid, errors }"> 
                        <b-form-input
                            class="form-control"
                            type="text"
                            v-model="order.customeragent"
                            placeholder="Ingrese nombre del referido"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                        </b-form-input>   
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="12">
                    <div class="float-right">
                        <b-button
                            variant="primary"      
                            :disabled="invalid || isSavingReference"
                            @click="updateReferencia(order)"
                        > <b-spinner small v-if="isSavingReference"/> Guardar
                        </b-button>
                    </div>                    
                </b-col>
            </b-row> 
        </ValidationObserver> 
    </b-tab>
    
  </b-tabs>
    </b-modal>   
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    props:{        
        order:{
            type: Object,
            required: true             
        }
    }, 
    data(){
        return {
            isSavingReference: false,
            isSavingCustomer: false,
        }
    },
     computed: {    
      ...mapState('auth',['user']),        
    },
    methods: {
      ...mapActions('mySales',['updateReference','updateCustomer']),       
      async updateReferencia(){                                   
        const payload =  {
            idOrder :  this.order.id,
            idUser : this.user.idUser,
            paymentreference: this.order.paymentreference ,
            customeragent: this.order.customeragent,
        }
        // console.log( payload)
        this.isSavingReference = true
        await this.updateReference( payload )  
        this.isSavingReference = false
      },
      async updateDataCustomer( customer ){
        const payload = {
            idUser : this.user.idUser,
            ...customer,
            surname: customer.lastname
        }
        this.isSavingCustomer = true
        await this.updateCustomer( payload )
        this.isSavingCustomer = false        
      },
      formatPhoneNumber(event){      
            let keyCode = (event.keyCode ? event.keyCode : event.which)            
            //43 es +, 48-57(numeros del 0 al 9, 32 espacio, 45 es - )
            const codesAllowed = [ 32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
            const respuesta = codesAllowed.includes(keyCode)
			if (!respuesta) {                                       
				event.preventDefault()
			}
        },
    }
}
</script>


