<template>
  <download-excel
    class="btn btn-success"
    :data="filteredOrdersItems"
    :fields="json_fields"
    worksheet="listado"
    :name="fileName"
    >
    <b-icon icon="cloud-download" aria-hidden="true"></b-icon><a :class="windowWidth < 767 ? 'd-none' : '' " > Excel</a>      
  </download-excel>        
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
    data() {
      return { 
        fileName: 'SalesPOS-'+this.fechaActual+'-'+this.user.name.split(' ').join('-')+'-'+this.user.lastname.split(' ').join('-')+'.xls',       
        //campos a mostrar en el excel
        json_fields: {
          'NO. DE ORDEN': "invoice",          
          'FECHA DE ORDEN': "fechaOrden",
          'TIPO DE CLIENTE': "tipoCliente",
          'NOMBRE DE CLIENTE': "cliente",
          'PRODUCTO':'productoDetalle',       
          VENDEDOR: "vendedor",
          'FECHA DE PAGO': "fechaPago",
          // 'Numero cupón' : 'numeroCupon',
          // 'Fecha de operación': "operationdate",
          'MÉTODO DE PAGO': "metodoPago",
          REFERENCIA: "referencia",
          'ESTATUS DE ORDEN':'estado',
          'PRECIO UNITARIO':'unitPrice',
          // 'Venta Bruta':'vBruto',
          CANTIDAD:'qty',
          DESCUENTO:'vDescuento',
          'TOTAL DE VENTA':'vTotal',
          'MONEDA':'moneda',
        },      
        json_meta: [
          [
            {
              key: "charset",
              value: "utf-8",
            },
          ],
        ],
      }
    },      
    props:{
      fechaActual: {
        type: String,
          required: true
      },
      user: {
        type: Object,
          required: true
      }
    },              
    computed:{
      ...mapGetters('mySales',['filteredOrdersItems']),
      ...mapState('appConfig',['windowWidth']),
    }
}
</script>

<style>
.btnExcel:hover{
    color: white;
  }
</style>


