<template>
  <b-modal
    size="lg"
    :id="'modal-resend-mail'+order.id"
    :title="order.invoice"
    ok-only
    hide-footer
>
    <b-row class="mb-2">
        <b-col md="6">
            <strong>Cliente: </strong>{{order.customername}} <br>
            <strong>Tipo de cliente: </strong>{{order.customertype}} <br>
            <strong>Email: </strong>{{order.customer.email}} <br>
            <strong>Teléfono: </strong>{{order.customer.phone}}

        </b-col>
        <b-col md="6">
            <strong>Vendedor: </strong>{{order.vendedor}} <br>
            <strong>Fecha orden: </strong>{{order.orderDate}} <br>
            <strong>Fecha pago: </strong>{{order.paymentdate}} <br>
            <strong>Confirmados: </strong>{{order.confirmados}}                
        </b-col>            
    </b-row>            
    <ValidationObserver ref="observerReference" v-slot="{ invalid }">
        <b-row>
            <b-col md="12">
                <ValidationProvider rules="required" name="email">
                <b-form-group label="Email" slot-scope="{ valid, errors }"> 
                <b-form-input
                    class="form-control"
                    type="email"
                    v-model="order.customer.email"
                    :state="errors[0] ? false : (valid ? true : null)" 
                    readonly
                >
                </b-form-input>   
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
                </ValidationProvider>
            </b-col>
            
            <b-col md="12">
                <div class="float-right">
                    <b-button
                        variant="primary"      
                        :disabled="invalid || isSendingEmail"
                        @click="resendEmailToCustomer(order.id)"
                    > <b-spinner small v-if="isSendingEmail"/> Reenviar email
                    </b-button>
                </div>                    
            </b-col>
        </b-row> 
    </ValidationObserver>
        <!-- <pre>order: {{order.id}}</pre> -->

    </b-modal>   
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    props:{        
        order:{
            type: Object,
            required: true             
        }
    }, 
    data(){
        return {
            isSendingEmail: false,            
        }
    },
     computed: {    
      ...mapState('auth',['user']),        
    },
    methods: {
      ...mapActions('mySales',['resendEmail']),       
      async resendEmailToCustomer( order ){                                                   
        this.isSendingEmail = true       
         await this.resendEmail( order )  
        this.isSendingEmail = false
      },      
    }
}
</script>


