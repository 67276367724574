<template>
    <b-card no-body>
        <b-card-body>
            <Filters
                :filtroDataCliente="filtroDataCliente"
                :filtroVentasList="filtroVentasList"
                :tipoFechaSelect="tipoFechaSelect"
                :tipoDataClienteSelect="tipoDataClienteSelect"
                :filter="filter"
                :dateYYMM="dateYYMM"
                :minYearMax="minYearMax"
                :dateCurrentInit="dateCurrentInit"
                :dateCurrentEnd="dateCurrentEnd"
                :perPage="perPage"
                :pageOptions="pageOptions"
                @get-Orders-by-Date-Range-filter="getOrdersByDateRangeFilter"
                @change-per-page="changePerPage"
                @filtrar-resultado-tabla="filtrarResultadoTabla"
                @clear-filtrar-resultado-tabla="clearFiltrarResultadoTabla"
                @clear-filter="resetFilter"
            />
            <TableMySales
                :filteredOrders="filteredOrders"
                :fields="fields"
                :currentPage="currentPage"
                :perPage="perPage"
                :filter="filter"
                :filterOn="filterOn"
                :sortBy="sortBy"
                :sortDesc="sortDesc"
                :sortDirection="sortDirection"
                :onFiltered="onFiltered"
                :isloadingProcess="isloadingProcess"
                @get-Orders-by-Date-Range-filter="getOrdersByDateRangeFilter"
            />
            <b-pagination
                v-if="filteredOrders.length && !isloadingProcess"
                v-model="currentPage"
                :total-rows="filteredOrders.length"
                :per-page="perPage"
                align="right"
                class="my-0"
            />
        </b-card-body>
    </b-card>
</template>

<script>
//eslint-disable-line
import * as moment from 'moment'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex' 
//data seeds 
import { fields, filtroVentasList, filtroDataCliente } from '@/data/data'   
//helpers
import { currentDate, yearsMinMax } from '@/helpers/helpers'   
//components
import Filters from '@/modules/mySales/components/mySales/Filters'
import TableMySales from '@/modules/mySales/components/mySales/TableMySales'


export default {
    async created() {
        if( !this.currencies.length ){
            const currencies = await this.fetchCurrencies()
            this.setCurrencies(currencies) //muto state currencies en start 
        }
        if (this.filteredOrders.length <= 0) this.setFiltersFromMySales(null)
    },
    components: {
        //SFC
        Filters,
        TableMySales
    },
    data() {
        return {
            tipoFechaSelect: 1,
            tipoDataClienteSelect: 'Elija una opción',
            fields,
            filtroVentasList,
            filtroDataCliente,
            idUserSelect: null,
            filter: null,
            perPage: 20,
            dateYYMM: moment().format("YYYY-MM"),
            dateCurrentInit: moment().format('YYYY-MM-01'),
            dateCurrentEnd: moment().endOf('month').format("YYYY-MM-DD"),
            fechaActual: currentDate(),
            minYearMax: yearsMinMax(),
            pageOptions: [10, 20, 30, { value: 200, text: "Todos" }],
            totalRows: 1,
            currentPage: 1,
            filterOn: [],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
        }
    },
    computed:{
        ...mapState('mySales',['usersChannel','isloadingProcess']),
        ...mapGetters('mySales',['filteredOrders']),
        ...mapState("start", ["currencies"]),
    },
    methods: {
      ...mapActions('mySales',['fetchOrdersByUser']),
      ...mapActions('operation',['getOrdersOperation']),
      ...mapMutations('mySales',['setOrders', 'setFiltersFromMySales']),
      ...mapActions("start", ["fetchCurrencies"]),
      ...mapMutations('start',['setCurrencies']),

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
      },
        async getOrdersByDateRangeFilter(data){
            const { tipoFecha, totalRows, inicio, fin, dateByMonth } = data
            this.tipoFechaSelect = tipoFecha,
            this.totalRows = totalRows, 
            this.dateCurrentInit = inicio
            this.dateCurrentEnd = fin 
            // this.dateYYMM = dateByMonth
            document.getElementById('dateStart').value = inicio
            document.getElementById('dateEnd').value = fin
            await this.fetchOrdersByUser(data)
        },
        async resetFilter(){
            this.setOrders([])
        },
        changePerPage( data ){
            this.perPage = data
        },
        filtrarResultadoTabla( data ){
            this.filter = data
        },
        clearFiltrarResultadoTabla(){
            this.filter = ''
        }
    },
}
</script>