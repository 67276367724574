<template>
    <div>
        <b-row>
            <b-col md="3" sm="6" cols="6">
                <label>Tipo de fecha*</label>
                <b-form-group>
                    <b-form-select :options="showFiltroVentasList" v-model="tipoFecha" ></b-form-select>
                </b-form-group>
            </b-col>
            <b-col md="3" sm="6" cols="6">
                <label>Usuario</label>
                <b-form-group >
                    <b-form-select
                        :disabled="isReloadingUsers"
                        v-model="idUserSelect"
                        @click="handleSubmit"
                    >
                        <option :value="0">Todos los usuarios</option>
                        <option v-for="users in selectTypeofUsers" :key="users.id" :value="users.id">{{ users.fullname }}</option>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col md="1">
                <label style="font-size:x-small">Usuario activo</label> <br>
                <b-form-checkbox v-model="isActive" name="check-button" switch/>
            </b-col>
            <b-col md="5">
                <label>Búsqueda</label>
                <b-input-group>
                    <b-form-input v-model="filtroText" type="search" placeholder="Buscar" @input="filtrarInfo" @keyup.enter="handleSubmit"
                    ></b-form-input>
                </b-input-group>
            </b-col>
            <b-col md="" sm="6" cols="6">
                <label>Registros</label>
                <b-form-group>
                    <b-form-select v-model="porPagina" id="perPageSelect" :options="pageOptions" @change="changePerPage"
                    ></b-form-select>
                </b-form-group>
            </b-col>
            <!-- <b-col md="3">
                <label for="">Meses</label>
                <b-form-group>
                    <b-form-input
                        type="month"
                        v-model="dateByMonth"
                        :min="minYearMax.yearMin+'-01'"
                        :max="minYearMax.yearMax+'-12'"
                        @input="getOrdersByMonth"
                    >
                    </b-form-input>
                </b-form-group>
            </b-col> -->
            
            <b-col md="" sm="6" cols="6">
                <label for="">Inicio</label>
                <b-form-group>
                    <b-form-input id="dateStart" type="date" :value="fechaInicio">
                    </b-form-input>
                </b-form-group>
            </b-col>
            <b-col md="" sm="6" cols="6">
                <label for="">Fin</label>
                <b-form-group>
                    <b-form-input type="date" id="dateEnd" :value="fechaFin">
                    </b-form-input>
                </b-form-group>
            </b-col>
            <b-col md="" sm="6" cols="6">
                <label>Filtrar por:</label>
                <b-form-group>
                    <b-form-select v-model="tipoBusqueda" @input="resetSearchType">
                        <option v-for="dc in showTipoBusqueda" :key="dc.value" :value="dc.text">{{dc.text}}</option>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col md="4" sm="6" cols="6" v-if="tipoBusqueda != 'Elija una opción' ">
                <label>Búsqueda por {{ tipoBusqueda }}</label>
                <b-input-group>
                    <b-form-input
                        :type="tipoBusqueda === 'Email' ? 'email' : 'text' "
                        :placeholder="'Buscar por ' + tipoBusqueda"
                        v-model="busquedaSelect"
                        @keyup.enter="handleSubmit"
                    />
                </b-input-group>
            </b-col>
        </b-row>
        <b-row :class="windowWidth > 906 ? 'mb-1' : 'mb-1 mt-1'">
            <b-col>
                <b-button variant="danger" class="mr-1" @click="clearFilter">
                    <feather-icon icon="TrashIcon" size="16" /> Limpiar
                </b-button>
            </b-col>
            <b-col>
                <div class="float-right">
                    <Excel :fechaActual="fechaExcel" :user="user" class="mr-1" v-if="filteredOrders.length > 0 && user"></Excel>
                    <b-button variant="success" @click="getOrdersByFilters" >
                        <b-icon icon="search"></b-icon><span v-if="windowWidth > 767"> Buscar</span>
                    </b-button>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import * as moment from 'moment'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'  
import Excel from '@/modules/mySales/components/mySales/Excel'
import { currentDate, typeFilter, sortalphabetically, showAlertMessage } from '@/helpers/helpers'

export default {
    async mounted(){
        if( !this.idUserSelect ){
            this.idUserSelect = 0
        }
        await this.fetchUsersByChannel( this.user.canalventa.id )
        // await this.getOrdersByMonth()  
    },
    components:{
        Excel
    },
    props:{
        tipoFechaSelect: {
            type: Number,
            required: true
        },
        tipoDataClienteSelect: {
            type: String,
            required: true
        },
        filtroVentasList: {
            type: Array,
            required: true
        },
        filtroDataCliente:{
            type: Array,
            required: true
        },
        filter:{
            type: String,
            required: false
        },
        dateYYMM:{
            type: String,
            required: false
        },
        minYearMax:{
            type: Object,
            required: true
        },
        dateCurrentInit:{
            type: String,
            required: false
        },
        dateCurrentEnd:{
            type: String,
            required: false
        },
        perPage:{
            type: Number,
            required: false
        },
        pageOptions:{
            type: Array,
            required: true
        }
    },
    data() {
        return {
            idUserSelect: null,
            tipoFecha: this.tipoFechaSelect,
            tipoBusqueda: this.tipoDataClienteSelect,
            busquedaSelect: '',
            fechaInicio: currentDate(),
            fechaFin: currentDate(),
            porPagina: this.perPage,
            fechaActual: currentDate(),
            fechaExcel: currentDate(),
            filtroText: this.filter,
            isActive: true,
            isReloadingUsers: false
        }
    },
    watch: {
        canalventa: {
            deep: true,
            async handler() {
                this.isReloadingUsers = true
                await this.fetchUsersByChannel( this.user.canalventa.id )
                this.isReloadingUsers = false
            },
        },
    },
    computed:{
      ...mapGetters('mySales',['filteredOrders']),
      ...mapState('auth',['user']),
      ...mapState('shop',['canalventa']),
      ...mapState('mySales',['usersChannel', 'activeUsers', 'inactiveUsers']),
      ...mapState('appConfig',['windowWidth']),
      selectTypeofUsers(){
        if (this.isActive) return this.activeUsers
        else return this.inactiveUsers
      },
      showFiltroVentasList(){
        return sortalphabetically(this.filtroVentasList)
      },
      showTipoBusqueda(){
        return sortalphabetically(this.filtroDataCliente)
      }
    },
    methods: {
      ...mapActions('mySales',['fetchUsersByChannel','fetchOrdersByUser','fetchOrderDetail']),
      ...mapMutations('mySales',['setFiltersFromMySales']),
        async getOrdersByMonth(data){
            if (data.valid) {
                this.$emit('get-Orders-by-Date-Range-filter', data.payload)
                this.setFiltersFromMySales(data.payload)
            }
            else if (data.payload.inicio == '' || data.payload.fin == '') showAlertMessage('Fechas incorrectas', 'BellIcon', 'Coloque ambas fechas correctamente', 'danger', 5000, 'bottom-right' )
            else return showAlertMessage('Fechas incorrectas', 'BellIcon', '😡 Fecha fin no debe ser menor a fecha de inicio', 'danger', 5000, 'bottom-right' )

        },
        getOrdersByFilters(){
            const fechaInicio = document.getElementById('dateStart').value
            const fechaFin = document.getElementById('dateEnd').value
            const isValidDates = moment(fechaFin).isSameOrAfter(fechaInicio, 'day')
            const payload = {
                tipoFecha: this.tipoFecha,
                inicio: fechaInicio,
                fin: fechaFin,
                idUser: this.idUserSelect,
                canalVenta: this.user.canalventa.id,
                totalRows: this.filteredOrders.length,
                dateByMonth: this.dateByMonth,
                ...typeFilter( this.tipoBusqueda, this.busquedaSelect),
            }
            if ( this.tipoBusqueda == "Elija una opción" && this.busquedaSelect == '' ) this.getOrdersByMonth({payload: payload, valid: isValidDates})
            else if( this.tipoBusqueda != "Elija una opción" && this.busquedaSelect != '' ) {
                this.$emit('get-Orders-by-Date-Range-filter', payload)
                this.setFiltersFromMySales(payload)
            }
            else return showAlertMessage('Filtro vacío', 'BellIcon', 'Por favor, coloque una busqueda en el filtro', 'danger', 5000, 'bottom-right' )
        },
        setUserChannel( idUsr ){
            const listUsers = JSON.parse(JSON.stringify( this.usersChannel ))
            const usr = listUsers.find( usr => usr.id === idUsr )
            if( usr ){
                this.idUserSelect = idUsr
            }
            if (typeof usr === 'undefined'){
                this.idUserSelect = idUsr
            }
            this.getOrdersByFilters()
        },
        filtrarInfo(){
            this.$emit('filtrar-resultado-tabla', this.filtroText)
        },
        clearFiltroText(){
            this.filtroText = ''
            this.$emit('clear-filtrar-resultado-tabla')
        },
        changePerPage( ){
            this.$emit('change-per-page', this.porPagina)
        },
        clearFilter(){
            this.tipoFecha = this.tipoFechaSelect,
            this.idUserSelect = 0
            this.filtroText = ''
            this.porPagina = 20
            // this.dateByMonth = moment().format("YYYY-MM")
            this.fechaInicio = currentDate()
            this.fechaFin = currentDate()
            this.tipoBusqueda = 'Elija una opción'
            this.busquedaSelect = ''
            this.setFiltersFromMySales(null)
            this.$emit('clear-filter')
        },
        handleSubmit(){
            this.getOrdersByFilters()
        },
        resetSearchType(){
            this.busquedaSelect = ''
        }
    },
}
</script>