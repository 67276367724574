var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"lg","id":'modal-reference'+_vm.order.id,"title":_vm.order.invoice,"ok-only":"","hide-footer":""}},[_c('b-tabs',[_c('b-tab',{attrs:{"title":"Cliente","active":""}},[_c('ValidationObserver',{ref:"observerReference",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Nombre"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.order.customer.name),callback:function ($$v) {_vm.$set(_vm.order.customer, "name", $$v)},expression:"order.customer.name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"apellido"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Apellido"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.order.customer.lastname),callback:function ($$v) {_vm.$set(_vm.order.customer, "lastname", $$v)},expression:"order.customer.lastname"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Email"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"email","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.order.customer.email),callback:function ($$v) {_vm.$set(_vm.order.customer, "email", $$v)},expression:"order.customer.email"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"","name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Teléfono"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","maxlength":"20","type":"text","state":errors[0] ? false : (valid ? true : null)},on:{"keypress":_vm.formatPhoneNumber},model:{value:(_vm.order.customer.phone),callback:function ($$v) {_vm.$set(_vm.order.customer, "phone", $$v)},expression:"order.customer.phone"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"float-right"},[_c('b-button',{attrs:{"variant":"primary","disabled":invalid || _vm.isSavingCustomer},on:{"click":function($event){return _vm.updateDataCustomer(_vm.order.customer)}}},[(_vm.isSavingCustomer)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Guardar ")],1)],1)])],1)]}}])})],1),_c('b-tab',{attrs:{"title":"Referencia"}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('strong',[_vm._v("Cliente: ")]),_vm._v(_vm._s(_vm.order.customername)+" "),_c('br'),_c('strong',[_vm._v("Tipo de cliente: ")]),_vm._v(_vm._s(_vm.order.customertype)+" "),_c('br'),_c('strong',[_vm._v("Email: ")]),_vm._v(_vm._s(_vm.order.customer.email)+" "),_c('br'),_c('strong',[_vm._v("Teléfono: ")]),_vm._v(_vm._s(_vm.order.customer.phone)+" ")]),_c('b-col',{attrs:{"md":"6"}},[_c('strong',[_vm._v("Vendedor: ")]),_vm._v(_vm._s(_vm.order.vendedor)+" "),_c('br'),_c('strong',[_vm._v("Fecha orden: ")]),_vm._v(_vm._s(_vm.order.orderDate)+" "),_c('br'),_c('strong',[_vm._v("Fecha pago: ")]),_vm._v(_vm._s(_vm.order.paymentdate)+" "),_c('br'),_c('strong',[_vm._v("Confirmados: ")]),_vm._v(_vm._s(_vm.order.confirmados)+" ")])],1),_c('ValidationObserver',{ref:"observerReference",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"","name":"Referencia"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Referencia"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Ingrese referencia","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.order.paymentreference),callback:function ($$v) {_vm.$set(_vm.order, "paymentreference", $$v)},expression:"order.paymentreference"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"","name":"Referido"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Referido"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Ingrese nombre del referido","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.order.customeragent),callback:function ($$v) {_vm.$set(_vm.order, "customeragent", $$v)},expression:"order.customeragent"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"float-right"},[_c('b-button',{attrs:{"variant":"primary","disabled":invalid || _vm.isSavingReference},on:{"click":function($event){return _vm.updateReferencia(_vm.order)}}},[(_vm.isSavingReference)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Guardar ")],1)],1)])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }